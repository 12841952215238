import React,{useState,useEffect} from 'react';
import './App.css'
import { Responsive,Icon } from 'semantic-ui-react'
import {Link} from 'react-router-dom';

const NavBar = (props) => {

const [activeItem, setActiveItem] = useState('')
const [activeIndex, setActiveIndex]=useState(1)
const [menuIcon, setMenuIcon]=useState("align justify")

useEffect(()=>{

},[activeItem,menuIcon])


function handleClickMenu(){
  const newIcon = menuIcon === "align justify" ? "reply" : "align justify"
  setMenuIcon(newIcon)
  const newIndex = activeIndex === 0 ? 1: 0
  setActiveIndex(newIndex)
}





  return (

    <div id='topcolor'>
    {window.frames.innerWidth < 1049 &&
    <Icon size='large'name={menuIcon} id='menu-icon' onClick={handleClickMenu}/>
    }
    {window.frames.innerWidth < 1049 &&
    <Link to="/"><img  src="/Money_Skilled.png" alt="money skilled logo
    " className={window.frames.innerWidth > 1049
      ? 'logo'
      : 'logo-Mobile'
    } /></Link>
    }






    <div id='mobile-nav-position'>
    {activeIndex === 0 &&
    <div id={window.frames.innerWidth > 1049
    ? 'navbar-PC-new'
    : 'navbar-PC-Mobile'
    }>

    <Responsive maxWidth={1048} >

      <ul id="navbar-new-ul-mobile">


      <li  id='mobile-nav-content'  onClick={(e) => {
          setActiveItem('Credit Card')
          setActiveIndex(1)
          setMenuIcon("align justify")
        }}>
        <Link to="/creditcard">Credit Card</Link>

      </li>


      <li id='mobile-nav-content'
      onClick={(e) => {
        setActiveItem('Loan')
        setActiveIndex(1)
        setMenuIcon("align justify")
        }}>
        <Link to="/personalloan">Loan</Link>

      </li>

        <li  id='mobile-nav-content'

          onClick={(e) => {
            setActiveItem('Budget Tool')
            setActiveIndex(1)
            setMenuIcon("align justify")
          }}>
        <Link to="/budgettool">Budget Tool</Link>

        </li>

        <li id='mobile-nav-content'

        onClick={(e) => {
          setActiveItem('Hints and Tips')
          setActiveIndex(1)
          setMenuIcon("align justify")
          }}>
          <Link to="/hintsandtips">Hints and Tips</Link>

        </li>

        <li id='mobile-nav-content'

        onClick={(e) => {
          setActiveItem('OveraAll')
          setActiveIndex(1)
          setMenuIcon("align justify")
          }}>
          <Link to="/overall">Overall</Link>

        </li>


      </ul>
      </Responsive>
      </div>

    }
    </div>




    <Responsive minWidth={1049} >
    <Link onClick={(e)=>{setActiveItem("")}} to="/"><img  alt="money skilled logo
    " src="/Money_Skilled.png" className={window.frames.innerWidth > 1049
      ? 'logo'
      : 'logo-Mobile'
    } /></Link>

      <ul id="navbar-new-ul">



      <li  id={activeItem === 'Credit Card'
        ? 'navbar-new-li-active'
        : 'navbar-new-li'}  onClick={(e) => {
          setActiveItem('Credit Card')
        }}>
        <Link to="/creditcard">Credit Card</Link>
        <div className={activeItem === 'Credit Card'
            ? 'overlay-active'
            : 'overlay'}></div>
      </li>

      <li id={activeItem === 'Loan'
        ? 'navbar-new-li-active'
        : 'navbar-new-li'}

      onClick={(e) => {
        setActiveItem('Loan')
        }}>
        <Link to="/personalloan">Loan</Link>
        <div className={activeItem === 'Loan'
            ? 'overlay-active'
            : 'overlay'}></div>
      </li>

        <li  id={activeItem === 'Budget Tool'
          ? 'navbar-new-li-active'
          : 'navbar-new-li'}

          onClick={(e) => {
            setActiveItem('Budget Tool')
          }}>
        <Link to="/budgettool">Budget Tool</Link>
        <div className={activeItem === 'Budget Tool'
            ? 'overlay-active'
            : 'overlay'}></div>
        </li>

        <li id={activeItem === 'Hints and Tips'
          ? 'navbar-new-li-active'
          : 'navbar-new-li'}

        onClick={(e) => {
          setActiveItem('Hints and Tips')
          }}>
          <Link to="/hintsandtips">Hints and Tips</Link>
          <div className={activeItem === 'Hints and Tips'
              ? 'overlay-active'
              : 'overlay'}></div>
        </li>


        <li id={activeItem === 'OverAll'
          ? 'navbar-new-li-active'
          : 'navbar-new-li'}

        onClick={(e) => {
          setActiveItem('OverAll')
          }}>
          <Link to="/overall">Overall</Link>
          <div className={activeItem === 'OverAll'
              ? 'overlay-active'
              : 'overlay'}></div>
        </li>


      </ul>

      </Responsive>



      </div>


  )
}

export default NavBar;
