import React from 'react'
import { Container, Header } from 'semantic-ui-react'


const GetStarted =(props)=>{




  return(
    <div id="cc-list">
     <div id={window.frames.innerWidth >1049
       ? 'max-width-getstarted'
       : 'max-width-getstarted-mobile'}>
      <div id={window.frames.innerWidth > 1049
       ? 'getstarted-size-PC'
       : "getstarted-size-Mobile"
      }>
      <Container>
      <Header as="h2">Credit Card</Header>
      <h5>
       On the top of the page you will find a small form with:
      </h5>
      <ol>
      <li>Amount</li>
      <li>Interest (APR) this is interest over the year.</li>
      <li>Monthly Payment</li>
      </ol>
      <h5>
      Please fill in the fields and the calculator will do the calculation automatically for you.
      This calculator will show you how long it will take to pay this credit card off and how much interest you will pay over that term.
      </h5>
      <ul>
      <li>Higher Monthly Payment (optional)</li>
      </ul>
      <h5>This field is optional.However, if you input higher payment than your initail monthly instalment the calculator will tell you how much time and interest you will save if you will increase your payment.</h5>
      </Container>
      </div>
    <div>
      <img alt="form explanation" src='/cc-scsht.png' id={window.frames.innerwidth > 1049
      ? 'getstarted-img-cc-mobile'
      : 'getstarted-img-cc'}/>
    </div>

  </div>


  <hr />

  <div id={window.frames.innerWidth >1049
    ? 'max-width-getstarted'
    : 'max-width-getstarted-mobile'}>
   <div id={window.frames.innerWidth > 1049
    ? 'getstarted-size-PC'
    : "getstarted-size-Mobile"
   }>
   <Container>
   <Header as="h2">Loan</Header>
   <h5>
    On the top of the page you will find a small form with:
   </h5>
   <ol>
   <li>Amount</li>
   <li>Interest (APR) this is interest over the year.</li>
   <li>Monthly Payment</li>
   </ol>
   <h5>
   This calculator will calculate monthly payment, interest and overall cost of this loan.
   </h5>
   </Container>
   </div>
 <div>
   <img alt="form explanation" src='/loan-scsht.png' id={window.frames.innerwidth > 1049
   ? 'getstarted-img-cc-mobile'
   : 'getstarted-img-cc'}/>
 </div>


</div>

<hr/>



<div id={window.frames.innerWidth >1049
  ? 'max-width-getstarted'
  : 'max-width-getstarted-mobile'}>
 <div id={window.frames.innerWidth > 1049
  ? 'getstarted-size-PC'
  : "getstarted-size-Mobile"
 }>
 <Container>
 <Header as="h2">Budget Tool</Header>
 <p>
  Allows you to input information about your spending(for is on the top of the page).We recommend to input transaction from last month. Simply type in:
 </p>
 <ol>
 <li>Description</li>
 <li>Amount you spend</li>
 </ol>
 <p>Last option is:</p>
 <li>Category</li>
 <p>Category allows you to change between Income and Outgoing. Simply input proper category and the budgeting tool will calculate totals for you.</p>
 <h5>In the middle you will be able to see how much you have left.</h5>
 <h5>
 This calculator will calculate overall spending and how much you have left from the month. It will allow you to check how much you can save or use to pay off your borrowing quicker!
 </h5>
 </Container>
 </div>
<div>
 <img alt="form explanation"  src='/budget-scsht.png' id={window.frames.innerwidth > 1049
 ? 'getstarted-img-cc-mobile'
 : 'getstarted-img-cc'}/>
</div>

</div>





  </div>

  )
}

export default GetStarted;
