import React, {useState, useEffect, Fragment} from 'react';
import './App.css';
import CreditCard from './components/creditcard.js'
import Loan from './components/loan.js'
import { Responsive } from 'semantic-ui-react'
import BudgetTool from './components/budgettool.js'
import Home from './components/home.js'
import HintsAndTips from './components/hintsandtips.js'
import GetStarted from './components/getstarted.js'
import Feedback from './components/feedback.js'
import ThankYou from './components/thankyou.js'
import Footer from './components/footer.js'
import OverAll from './components/overall.js'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

// import { v4 as uuidv4 } from 'uuid';
import NavBar from './NavBar.js'
import {FacebookShareButton,RedditShareButton,EmailShareButton,TwitterShareButton} from 'react-share'

import {FacebookIcon,TwitterIcon,RedditIcon,EmailIcon} from 'react-share'
// import ReactGA from 'react-ga'
// 

// const TRACKING_ID = 'UA-175060596-1';
// ReactGA.initialize(TRACKING_ID);
function App() {





const [budgettool, setBudgettool]= useState([])
const [ccdata, setCcdata]=useState({
  amount: '',
  interest: '',
  monthlyPayment: '',
  higherMonthlyPayment: '',
  chartData: [['a','a']],
  chartDataSavings: [['a','a']]
})
const [loandata, setLoandata]=useState({
  amount: 0,
  interest: 0,
  term: 0
})
const [chartClear, setChartClear]=useState([['Other','Other']])
const [overalldata, setOveralldata]=useState([

])
// const initGA=()=>{
//   console.log(uuidv4());

//   ReactGA.initialize('UA-175245149-2', {
//     debug: true,
//     titleCase: false,
//     gaOptions: {
//       userId: uuidv4()
//     }
//   });
//   ReactGA.pageview(window.location.pathname + window.location.search)
//   console.log("I'm hit");
// }

useEffect(()=>{

  // ReactGA.pageview(window.location.pathname)
// initGA()

},[])

function handledataCC(data){
  setCcdata(data)
}
function handledataBT(data){
  setBudgettool(data)
}
function handledataLoan(data){
  // ReactGA.event(
  //   {
  //     category: Item.name,
  //     action:"test action",
  //     label: 'test label',
  //     value: Item.price
  //   }
  // )
  setLoandata(data)
}
function handledataOverall(data){
  setOveralldata(data)
}

  return (
    <div id="app">

    <Router >

      <Fragment>
      <div id={window.frames.innerWidth > 1049
      ? 'socialmedia'
      : 'socialmedia-Mobile'}>
      <FacebookShareButton url={'www.moneyskilled.com'} ><FacebookIcon size={32} round={true}/></FacebookShareButton>

      <TwitterShareButton url={'77.79.202.22'} ><TwitterIcon size={32} round={true}/></TwitterShareButton>
      < RedditShareButton url={'77.79.202.22'} ><RedditIcon size={32} round={true}/></ RedditShareButton>
      <EmailShareButton url={'77.79.202.22'} ><EmailIcon size={32} round={true}/></EmailShareButton>
      </div>
        <NavBar />

        <Switch>

          <Route path="/personalloan"  render={(props)=>{
            return <Loan  handledataLoan={handledataLoan}loandata={loandata}/>
          }} />
          <Route path="/creditcard"   render={(props)=>{
            return <CreditCard  handledataCC={handledataCC} ccdata={ccdata}/>
          }}/>
          <Route path="/budgettool"  exact   render={(props)=>{
            return <BudgetTool handledataBT={handledataBT}budgettool={budgettool}/>
          }}/>
          <Route path="/hintsandtips" exact  render={(props)=>{
            return <HintsAndTips  /*handleBudgetPress={handleBudgetPress}*//>
          }}/>
          <Route path="/home" exact  render={(props)=>{
            return <Home />
          }}/>
          <Route path="/overall" exact  render={(props)=>{
            return <OverAll chartClear={chartClear} handledataOverall={handledataOverall}overalldata={overalldata}/>
          }}/>
          <Route exact path="/getstarted" render={(props)=>{
              return  <GetStarted/>}}/>
          <Route exact path="/feedback" render={(props)=>{
              return  <Feedback/>}}/>
          <Route exact path="/thankyou" render={(props)=>{
              return  <ThankYou/>}}/>
              <Route exact path="/" render={(props)=>{
                return <Home />
              }}/>


        </Switch>
        <div id="commercials">

              <Responsive {...Responsive.onlyMobile}>
              <div id='addsMobile'></div>
              </Responsive>
        </div>
        <div id="148657854">

</div>
        <Footer id="footer"/>

      </Fragment>
    </Router>

{/*<main>




      <Responsive {...Responsive.onlyMobile}>
      <div id='addsMobile'></div>
      </Responsive>


</main>*/}





    </div>

  );
}

export default App;
