import React,{useEffect, useState} from 'react';
import '../App.css';
import {Form, Segment,Container,Header } from 'semantic-ui-react'
import Chart from "react-google-charts";
import { Label, Grid } from "semantic-ui-react";



const CreditCard = (props) =>{
  const [amount, setAmount] = useState(props.ccdata.amount)
  const [interest, setInterest] = useState(props.ccdata.interest)
  const [instalment, setInstalment] = useState(props.ccdata.monthlyPayment)
  const [term, setTerm] = useState(null)
  const [termMonths, setTermMonths]= useState(null)
  const [overallInterest, setOverallInterest]= useState(0)

  const [minPayment, setMinPayment]= useState(0)
  const [totalPayable, setTotalPayable] = useState(null)
  const [displayalert, setDisplayalert] = useState(false)
  const [instalmentSaving, setInstalmentSaving] = useState(props.ccdata.higherMonthlyPayment)
  const [savingTerm, setSavingsTerm]=useState(0)
  const [savingTermMonths, setSavingTermMonths] = useState(null)
  const [savingsOverInterest, setSavingsOverInterest] = useState(0)
  const [overallIntSav, setOverallIntSav]= useState(null)
  const [readyToDisplay,setReadyToDisplay]=useState(true)
  const [readyToDisplaySavings,setReadyToDisplaySavings]=useState(true)
  const [chartData, setChartData]=useState(props.ccdata.chartData)
  const [chartDataSavings, setChartDataSavings]=useState(props.ccdata.chartDataSavings)
  const [chartSize, setChartSize]=useState([])

  useEffect(()=>{
    checkSizes()
    calculate()
    calculatesavings()
    handledataCC()

  }, [instalmentSaving,amount,interest, instalment, ])


function checkSizes(){
  if (window.frames.innerWidth > 1049){
    setChartSize([
      window.frames.innerWidth *0.4,
      window.frames.innerHeight *0.5
    ])
  }else{
    setChartSize([
      window.frames.innerWidth *0.8,
      window.frames.innerHeight *0.5
    ])
  }

  if(props.ccdata.amount != null && props.ccdata.interest != null &&props.ccdata.monthlyPayment != null){
    setChartData(true)

    if(props.ccdata.higherMonthlyPayment != null){
      setChartDataSavings(true)

    }
  }
}



  function handledataCC(){
    props.handledataCC({
      amount: amount,
      interest: interest,
      monthlyPayment: instalment,
      higherMonthlyPayment: instalmentSaving,
      chartData: chartData,
      chartDataSavings: chartDataSavings
    })
  }


   function calculate(){
    setDisplayalert(false)
    let months = 0
    let payoff = amount
    let dailyRate = ((interest/100)/ 365)

    setMinPayment(((payoff + (payoff *dailyRate))*0.0153).toFixed(0))

    let overallInterest= 0
    if(instalment< ((payoff + (payoff *dailyRate))*0.0153).toFixed(0)){
      const x =((payoff + (payoff *dailyRate))*0.0153).toFixed(0)
    if(amount > 0 && interest > 0 && instalment >0){
      setDisplayalert(true)
    }
    }




    while(payoff>0&&months<252&&instalment>=minPayment){
      if(payoff < instalment){
        months += 1
        const x = (payoff * (dailyRate *28.065))
          overallInterest += (x * (dailyRate *28.065))
        payoff = 0
      }else{
       overallInterest += (payoff * (dailyRate *28.065))
        months += 1
        payoff = payoff + (payoff * (dailyRate *28.065))
        payoff = payoff - instalment
      }
    }



    if(months >= 252){
      setOverallInterest(0)
      setTotalPayable(0)
      setTerm(0)
      setTermMonths(0)
    }else{

    setOverallInterest((overallInterest).toFixed(2))
    if(amount){
    setTotalPayable( (overallInterest + payoff+ amount).toFixed(2) )
    }else{
      setTotalPayable( parseFloat(0.00) )
    }

    if(months !== 0 || months !== null || months !== 252){

    let termCount = months
    let yearsCount = 0

    while(termCount > 0 && months <=252&& termCount >=12){
        yearsCount += 1
        termCount -= 12
    }
    setTerm(yearsCount)
    setTermMonths(termCount)
    if(amount >0&& interest >0 && instalment >0&& totalPayable >0 && overallInterest > 0){
      setReadyToDisplay(true)
      setChartData([['CREDIT','CARD'],['Amount Borrowed',amount],['Interest',overallInterest]])
    }else{
      setReadyToDisplay(false)
    }




    }




  }
  }




  function calculatesavings(){

    if(instalment >= minPayment && instalment < instalmentSaving ){
    setSavingsOverInterest(0)
    setSavingsOverInterest(0)
    setOverallIntSav(0)
    setSavingsTerm(0)
    setSavingTermMonths(0)
    if(instalment < instalmentSaving && instalmentSaving>minPayment){



    let months = 0
    let payoff = amount
    let dailyRate = ((interest/100)/ 365)
    let overallInterestSav= 0

    while(payoff>0 && months<252&&instalmentSaving>=minPayment&& instalment<instalmentSaving){

      if(payoff < instalmentSaving){
        months +=1
        const x =(payoff * (dailyRate *28.065))
         overallInterestSav += (x * (dailyRate *28.065))
        payoff = 0
      }else{
         overallInterestSav += (payoff * (dailyRate *28.065))
        months += 1
        payoff = payoff + (payoff * (dailyRate *28.065))
        payoff = payoff - instalmentSaving
      }
    }

    if (months<252&&instalmentSaving>=minPayment&& instalment<instalmentSaving){

      if(months !== 0 || months !== null || months !== 252){

      let termCount = months
      let yearsCount = 0

      while(termCount > 0 && months <=252&& termCount >=12){
          yearsCount += 1
          termCount -= 12
      }
      setSavingsTerm(yearsCount)
      setSavingTermMonths(termCount)
    }


    if(instalmentSaving>=minPayment&& instalment<instalmentSaving){

      const c = overallInterest - (overallInterestSav).toFixed(2)
      if(c === 0){
        setSavingsOverInterest(0)
      }else{
        setSavingsOverInterest((c).toFixed(2))
      }
      setOverallIntSav((overallInterestSav).toFixed(2))
      if(instalment < instalmentSaving && instalmentSaving>minPayment&& totalPayable >0){
      setReadyToDisplaySavings(true)
      setChartDataSavings([['WITH HIGHER','MONTHLY PAYMENT'],['Amount Borrowed',parseFloat(amount)],['Interest',parseFloat(overallInterestSav)]])
    }
    }




   }
  }
}else{
  setReadyToDisplaySavings(false)
  setSavingsOverInterest(0)
  setSavingsOverInterest(0)
  setOverallIntSav(0)
  setSavingsTerm(0)
  setSavingTermMonths(0)
}
}



  function handleAmountChange(event){
    setAmount(parseInt(event.target.value))
  }
  function handleInterestChange(event){
    setInterest(parseFloat(event.target.value))
  }
  function handleInstalmentChange(event){
    setInstalment(parseInt(event.target.value))

  }
  function handleInstalmentSavingChange(event){
    setInstalmentSaving(parseInt(event.target.value))
  }

  return(

    <div id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : 'cc-list-Mobile'}>

    <div id="max-width">

    <div id="148657854">

</div>




    <Form id={window.frames.innerWidth > 1049
    ? 'form-position-big'
    : 'form-position'} color={'green'}>

    <Header as='h5' color={'green'}>PLEASE FILL IN DETAILS OF YOUR CREDIT CARD</Header>
         <Form.Group id={window.frames.innerWidth > 1049
         ? 'a'
         : 'form-align'} inverted>

         <Form.Field>
           <label>Amount Borrowed</label>
           <input className="input-amount" type="number"  name="amount"  value={amount} onChange={handleAmountChange} placeholder='Amount Borrowed' />
           <p class="examples">example: 1000</p>
         </Form.Field>
         <Form.Field>
         <label>Interest (APR)</label>
           <input className="input-amount" type="number"  name="amount"  value={interest} onChange={handleInterestChange} placeholder='Interest' />
           <p class="examples">example: 20</p>
         </Form.Field>
         <Form.Field>
         <label>Mothly Payment</label>
           <input className="input-amount" type="number"  name="amount"  value={instalment} onChange={handleInstalmentChange} placeholder='Monthly Payment' />
           <p class="examples">example: 16</p>
         </Form.Field>

         <Form.Field>
         <label>Higher Monthly Payment(Optional)</label>
           <input className="input-amount" type="number"  name="amount"  value={instalmentSaving} onChange={handleInstalmentSavingChange} placeholder='Higher Monthly Payment' />
         </Form.Field>
       </Form.Group>
    </Form>














    {displayalert
      ?  <Label as='a' basic color='red' >It will take more than 20 years to pay it back</Label>
      :  <p></p>
    }







    {readyToDisplay  &&
      <div className="chart-cc">

            <Chart id='chart1'
      width={chartSize[0]}
      height={chartSize[1]}
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={{
        title: "STANDARD PAYMENTS",
        sliceVisibilityThreshold: 0.005,// 0,5%
        // backgroundColor: '#E4E4E4',
        backgroundColor: { fill:'transparent' },
         is3D: true,
      }}
      rootProps={{ 'data-testid': '7' }}
      />
      </div>
    }

    {readyToDisplaySavings  &&
      <div className="chart-cc">

            <Chart id='chart1'
      width={chartSize[0]}
      height={chartSize[1]}
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={chartDataSavings}
      options={{
        title: "WITH HIGHER MONTHLY PAYMENT",
        sliceVisibilityThreshold: 0.005,// 0,5%
        backgroundColor: '#E4E4E4',
         is3D: true,
      }}
      rootProps={{ 'data-testid': '7' }}
      />
      </div>
    }
    {savingsOverInterest > 0 &&
     <Segment id="headercc-popup"><Container><p id="savings-banner">If you increase your payment You will save: {savingsOverInterest}</p></Container></Segment>

    }


    {readyToDisplay  &&
    <Segment placeholder id="segment">
        <Grid columns={readyToDisplaySavings
          ?2
          :1} relaxed='very' stackable>


          <Grid.Column verticalAlign='middle'>
            <Container>
            <Header id='headercc' as="h3">Standard







            </Header>
             <Segment size="small"><span id="cc-term" as="h5">{term}</span> years <span id="cc-term" as="h5">{termMonths}</span> months TERM</Segment><Segment size="small"><span id="cc-term">{overallInterest}</span> INTEREST</Segment><Segment size="small"><span id="cc-term">{totalPayable}</span> TOTAL PAYABLE</Segment>
            </Container>
          </Grid.Column>





          {readyToDisplaySavings &&
          <Grid.Column verticalAlign='middle'>

            <Container>
            <Header id='headercc'as="h3">Faster</Header>
             <Segment size="small"><span id="cc-term-sav" as="h5">{savingTerm}</span> years <span id="cc-term-sav" as="h5">{savingTermMonths}</span> months TERM</Segment><Segment size="small"><span id="cc-term-sav">{overallIntSav}</span> INTEREST</Segment><Segment size="small"><span id="cc-term-sav">{(totalPayable - savingsOverInterest).toFixed(2)

             }</span> TOTAL PAYABLE</Segment>
            </Container>
          </Grid.Column>
        }
        </Grid>
      </Segment>
      }




      </div>
    </div>
  )
}
export default CreditCard;
