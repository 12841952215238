import React from 'react'


const ThankYou= (props)=>{



  return(
    <div  id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : 'cc-list-Mobile'} >
      <div id='max-width'>
        <h1>Thank you for your Feedback!</h1>
      </div>
    </div>

  )

}
export default ThankYou
