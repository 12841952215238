import React,{useState, useEffect} from 'react'
import { Divider, Header, Icon, Table,Label } from 'semantic-ui-react'
import {Form } from 'semantic-ui-react'
import Chart from "react-google-charts";

const OverAll = (props) => {
const [category, setCategory]=useState('Category')
const [display,setDisplay]=useState(null)
const [counter, setCounter]=useState(0)
const [lending, setLending]=useState(props.overalldata)
const [summary, setSummary]=useState(0)
const [summaryinterest, setSummaryinterest]=useState(0)
const [summaryDifference, setSummaryDifference]=useState(null)
const [displayCCAlert, setDisplayCCAlert]=useState(null)
const [chartDataTotalPayable, updateChartDataTotalPayable]= useState([['Other','Other']])
const [chartDataInterestOnly, updateChartDataInterestOnly]=useState([['Other','Other']])
const [chartdata, updateChartdata]=useState([['what','what']])
const [chartTitle, setChartTitle]=useState('Total Payable')
const [dataTitle, setDataTitle]=useState('total')
const [chartWidth, setChartWidth]=useState(window.frames.innerWidth *0.8)
const [chartHeight, setChartHeight]=useState(window.frames.innerHeight *0.4)
const [totalInterestBasic, setTotalInterestBasic]=useState(0)
const [ifDeletedOne, setIfDeletedOne]=useState(false)

  useEffect(()=>{
    calculateStuff()

    calcSummary()

    handleRender()
    handledataOverall()
  },[lending,counter])

  function handledataOverall(){
    props.handledataOverall(lending)
  }




function calculateStuff(){

  for (var lend of lending){
    calculate(lend)
  }
}


function handleDeleteNewPayment(event){
  event.preventDefault()
  setDisplay(null)
  setCounter( counter + 1)
  const newArray = lending
  const newEntry = lending[parseInt(event.target.value)]
  delete newEntry.newMonthlyPayment
  calculateWithoutChartAdding(newEntry)

  const index = parseInt(event.target.value) + 1
  const newChartdata = chartDataTotalPayable
  const newChartdataInterest = chartDataInterestOnly
  newChartdata.splice(index,1,[newEntry.name,newEntry.totalPayable])
  newChartdataInterest.splice(index,1,[newEntry.name,newEntry.interestcost])

  updateChartDataInterestOnly(newChartdataInterest)
  updateChartDataTotalPayable(newChartdata)

  newArray.splice(parseInt(event.target.value),1, newEntry)
  setLending(newArray)
  calcSummary()



}
function handleDelete(event){
    setIfDeletedOne(true)
    event.preventDefault()
    setDisplay(null)
    setCounter( counter + 1)
    const index = (parseInt(event.target.value) + 1)

    const chartdata= chartDataTotalPayable
    const chartdataInterest= chartDataInterestOnly
    chartdataInterest.splice(index,1)
    chartdata.splice(index,1)
    updateChartDataInterestOnly(chartdataInterest)
    updateChartDataTotalPayable(chartdata)


    const newArray = lending
    newArray.splice(parseInt(event.target.value),1)
    setLending(newArray)


}

function handleRender(){

   let rendered = lending.map((item, index)=>{ return ( <Table.Row key={index}>
    <Table.Cell width={2}>{item.category}</Table.Cell>
    <Table.Cell >{item.name}</Table.Cell>
    <Table.Cell>{item.totalPayable}</Table.Cell>
    <Table.Cell>{item.newMonthlyPayment > item.monthlyPayment
      ? item.newMonthlyPayment
      : item.monthlyPayment} </Table.Cell>
    <Table.Cell >{item.category !== 'Loan'&&

  <div id="btn-delete-higherPayment"><form onSubmit={handleHigherMonthlyPayment}>{item.newMonthlyPayment
    ? <div>{item.newMonthlyPayment}<button value={index}onClick={handleDeleteNewPayment} >X</button></div>
    :<div><input alt={index} id="overall-input-higherpayment"/><button  type='submit'>Change</button></div>}

    </form></div>}</Table.Cell>
    <Table.Cell><button value={index}onClick={handleDelete} >X</button></Table.Cell>
  </Table.Row>)})

    setDisplay(rendered)
}


function handleHigherMonthlyPayment(event){


  event.preventDefault()
  setDisplay(null)
  setCounter( counter + 1)
  const newArray = lending
  const newEntry = lending[parseInt(event.target[0].alt)]
  newEntry.newMonthlyPayment = parseFloat(event.target[0].value)
    calculateWithoutChartAdding(newEntry)


  const index = parseInt(event.target[0].alt)+ 1

  const newChartdata = chartDataTotalPayable
  const newChartdataInterest = chartDataInterestOnly
  newChartdata.splice(index,1,[newEntry.name,newEntry.totalPayable])
  newChartdataInterest.splice(index,1,[newEntry.name,newEntry.interestcost])
  updateChartDataInterestOnly(newChartdataInterest)
  updateChartDataTotalPayable(newChartdata)

  newArray.splice(parseInt(event.target[0].alt),1, newEntry)
  setLending(newArray)

}



function calcSummary(){

    let previousInterestConst = summaryinterest

    let totalCost = 0
    let totalInterest = 0
    let basicCost = true


  for (var lend of lending){
    totalCost += lend.totalPayable
    totalInterest += lend.interestcost
    if(lend.newMonthlyPayment){
      basicCost = false
    }
  }

  if(basicCost){
    setTotalInterestBasic(totalInterest)
  }

  const whatICanSave = totalInterestBasic - totalInterest

  setSummary(parseFloat((totalCost).toFixed(2)))
  setSummaryinterest(parseFloat((totalInterest).toFixed(2)))

  if(previousInterestConst > totalInterest&& previousInterestConst !== 0 && ifDeletedOne === false){
        setSummaryDifference( (whatICanSave).toFixed(2) )
  }else{
    setSummaryDifference(0)
  }



  if(dataTitle === 'interest'){
  updateChartdata(chartDataInterestOnly)
}else if(dataTitle === 'total'){
  updateChartdata(chartDataTotalPayable)
}
  setIfDeletedOne(false)


}
function handleCategory(event){
  setCategory(event.target.value)
}

function handleForm(event){
  let goAhead = false
  setDisplayCCAlert(null)

  let minPayment = parseFloat(((parseFloat(event.target[2].value) + (parseFloat(event.target[2].value) *((parseFloat(event.target[3].value)/100)/ 365)))*0.0153).toFixed(0))


  if(event.target[0].value === "Credit Card" || event.target[0].value === "Store Card"){
    if(parseFloat(event.target[2].value) > 10 && parseFloat(event.target[3].value) > 1 && parseFloat(event.target[4].value) >= minPayment)
    {
      goAhead = true
    }else{
      setDisplayCCAlert(<Label as='a' basic color='red' >Please input correct details</Label>)
    }
  }



  if(event.target[0].value === "Loan"){
    if(parseFloat(event.target[2].value) > 10 && parseFloat(event.target[3].value) > 1 && parseFloat(event.target[4].value) > 0)
    {
      goAhead = true
    }else{
        setDisplayCCAlert(<Label as='a' basic color='red' >Please input correct details</Label>)
    }
  }


  if(goAhead){
  const  newLending = {
    category: event.target[0].value,
    name: (event.target[1].value).toUpperCase(),
    amount: parseFloat(event.target[2].value),
    interest: parseFloat(event.target[3].value),
  }

  if(category === 'Loan'){
    newLending.years = parseInt(event.target[4].value)
  }else{
    newLending.monthlyPayment = parseFloat(event.target[4].value)
  }

  calculateWithoutChartAdding(newLending)
  setLending([...lending, newLending])

}
}
function calculateWithoutChartAdding(lend){

  if (lend.category === "Loan"){


    let overallInterestt = lend.amount * ((lend.interest/100)/1.763) * (lend.years)
    overallInterestt = parseFloat(overallInterestt)
    lend.interestcost = parseFloat((overallInterestt).toFixed(2))
    lend.totalPayable = parseFloat((overallInterestt + lend.amount).toFixed(2))
    const temp = parseFloat(lend.totalPayable / (lend.years*12))
      lend.monthlyPayment = parseFloat((temp).toFixed(2))

      if(lend.newMonthlyPayment > lend.monthlyPayment){

      }
      console.log(lend);


  }/*=============================== Credit Card/Store Card ====================================*/
  else{


        let x
    if(lend.newMonthlyPayment > lend.monthlyPayment){
         x = parseFloat(lend.newMonthlyPayment)
    }else{
       x = parseFloat(lend.monthlyPayment)
    }

    let instalment = x

    let months = 0
    let payoff = parseInt(lend.amount)

    let dailyRate = ((lend.interest/100)/ 365)
    let minPayment = parseFloat(((payoff + (payoff *dailyRate))*0.0153).toFixed(0))
    let overallInterest= 0

    if(!lend.category || lend.amount < 10 || lend.interest <= 0 || lend.monthlyPayment < minPayment ){
      setDisplayCCAlert( <Label as='a' basic color='red' >Something went wrong please input correct details</Label>)
    }else{


    if(instalment < ((payoff + (payoff *dailyRate))*0.0153).toFixed(0)){
      // const xb =((payoff + (payoff *dailyRate))*0.0153).toFixed(0)
    if(payoff > 0 && lend.interest > 0 && instalment >0){
      console.log('wrong input');
    }
    }




    while(payoff>0&&months<252&&instalment>=minPayment){
      if(payoff < instalment){
        months += 1
        const x = (payoff * (dailyRate *28.065))
          overallInterest += (x * (dailyRate *28.065))
        payoff = 0
      }else{
       overallInterest += (payoff * (dailyRate *28.065))
        months += 1
        payoff = payoff + (payoff * (dailyRate *28.065))
        payoff = payoff - instalment
      }
    }


    if(months >= 252){
      alert('WIWOFAHOIWHF WRONG INPUT')
    }else{

    lend.interestcost = parseFloat((overallInterest).toFixed(2))
    lend.totalPayable =  parseFloat((overallInterest + payoff+ lend.amount).toFixed(2))




    if(months !== 0 || months !== null || months !== 252){

    let termCount = months
    let yearsCount = 0

    while(termCount > 0 && months <=252&& termCount >=12){
        yearsCount += 1
        termCount -= 12
    }
    lend.years = yearsCount
    lend.months = termCount
    }}}}}


function calculate(lend){

  if (lend.category === "Loan"){


    let overallInterestt = lend.amount * ((lend.interest/100)/1.763) * (lend.years)
    overallInterestt = parseFloat(overallInterestt)
    lend.interestcost = parseFloat((overallInterestt).toFixed(2))
    lend.totalPayable = parseFloat((overallInterestt + lend.amount).toFixed(2))
    const temp = parseFloat(lend.totalPayable / (lend.years*12))
      lend.monthlyPayment = parseFloat((temp).toFixed(2))

      if(lend.newMonthlyPayment > lend.monthlyPayment){

      }



  }/*=============================== Credit Card/Store Card ====================================*/
  else{


        let x
    if(lend.newMonthlyPayment > lend.monthlyPayment){
         x = parseFloat(lend.newMonthlyPayment)
    }else{
       x = parseFloat(lend.monthlyPayment)
    }

    let instalment = x

    let months = 0
    let payoff = parseInt(lend.amount)

    let dailyRate = ((lend.interest/100)/ 365)
    let minPayment = parseFloat(((payoff + (payoff *dailyRate))*0.0153).toFixed(0))
    let overallInterest= 0

    if(!lend.category || lend.amount < 10 || lend.interest <= 0 || lend.monthlyPayment < minPayment ){
      setDisplayCCAlert( <Label as='a' basic color='red' >Something went wrong please input correct details</Label>)
    }else{


    if(instalment < ((payoff + (payoff *dailyRate))*0.0153).toFixed(0)){
      // const xb =((payoff + (payoff *dailyRate))*0.0153).toFixed(0)
    if(payoff > 0 && lend.interest > 0 && instalment >0){
      console.log('wrong input');
    }
    }
    while(payoff>0&&months<252&&instalment>=minPayment){
      if(payoff < instalment){
        months += 1
        const x = (payoff * (dailyRate *28.065))
          overallInterest += (x * (dailyRate *28.065))
        payoff = 0
      }else{
       overallInterest += (payoff * (dailyRate *28.065))
        months += 1
        payoff = payoff + (payoff * (dailyRate *28.065))
        payoff = payoff - instalment
      }
    }

    if(months >= 252){
      alert('WIWOFAHOIWHF WRONG INPUT')
    }else{

    lend.interestcost = parseFloat((overallInterest).toFixed(2))
    lend.totalPayable =  parseFloat((overallInterest + payoff+ lend.amount).toFixed(2))

    if(months !== 0 || months !== null || months !== 252){
    let termCount = months
    let yearsCount = 0
    while(termCount > 0 && months <=252&& termCount >=12){
        yearsCount += 1
        termCount -= 12
    }
    lend.years = yearsCount
    lend.months = termCount
    }
  }
    }
  }


  const newArrayTotal= chartDataTotalPayable
  const newArrayInterest= chartDataInterestOnly
  const index = lending.indexOf(lend)

  if(chartDataTotalPayable[index + 1] === undefined){
    newArrayTotal.push([lend.name,lend.totalPayable])
    newArrayInterest.push([lend.name,lend.interestcost])
    updateChartDataInterestOnly(newArrayInterest)
    updateChartDataTotalPayable(newArrayTotal)
  }else{
    console.log('im here');

}



}



function handleChartData(event){
  if(event.target.value === "total"){
    updateChartdata(chartDataTotalPayable)
    setDataTitle('total')
    setChartTitle('Total Payable')
  }else{
    updateChartdata(chartDataInterestOnly)
    setDataTitle('intrest')
    setChartTitle('Interest Only')
  }
}



































  return(
    <div  id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : 'cc-list-Mobile'}>
    <div id='max-width'>





    <Form onSubmit={handleForm} id={window.frames.innerWidth > 1049
    ? 'form-position-big'
    : 'form-position'} color={'green'}>

    <Header as='h5' color={'green'}>PLEASE FILL IN DETAILS OF YOUR BORROWING</Header>
         <Form.Group id={window.frames.innerWidth > 1049
         ? 'a'
         : 'form-align'} inverted>

         <Form.Field>
        <label>Category</label>
         <Form.Field  control='select' onChange={handleCategory}>
         <label>Category</label>
         <option disabled value='Category'>Category</option>
          <option value='Credit Card'>Credit Card</option>
          <option value='Store Card'>Store Card</option>
          <option value='Loan'>Loan</option>
        </Form.Field>
          </Form.Field>


          {category &&
         <Form.Field>
           <label>Name</label>
           <input className="input-amount" type="text"  name="name"   placeholder='Name' />
         </Form.Field>
       }
        {category &&
          <Form.Field>
            <label>Amount Borrowed</label>
            <input className="input-amount" type="number"  name="amount"   placeholder='Amount Borrowed' />
          </Form.Field>
        }
       {category &&
         <Form.Field>
         <label>Interest (APR)</label>
           <input className="input-amount" type="number"  name="interest" step="0.01"  placeholder='Interest' />
         </Form.Field>
      }
      {category !== 'Loan'&&
         <Form.Field>
         <label>Mothly Payment</label>
           <input className="input-amount" type="number"  name="monthly payment"   placeholder='Monthly Payment' />
         </Form.Field>
       }
       {category === 'Loan'&&
          <Form.Field>
          <label>Years</label>
            <input className="input-amount" type="number"  name="term"   placeholder='Term' />
          </Form.Field>
        }




       </Form.Group>
       <button id="">ADD</button>
       {displayCCAlert}
    </Form>
















        {chartdata.length > 1  &&
  <React.Fragment >
    <Divider horizontal>
      <Header as='h4'>
        <Icon name='tag' />
        Chart
      </Header>
    </Divider>





<div >
<button value="total"onClick={handleChartData}>Total Payable</button><button onClick={handleChartData} value="interest">Interest Only</button>
<div id="chart">

      <Chart id='chart1'
width={chartWidth}
height={chartHeight}
chartType="PieChart"
loader={<div>Loading Chart</div>}
data={chartdata}
options={{
  title: chartTitle,
  sliceVisibilityThreshold: 0.005,// 0,5%
  // backgroundColor: '#E4E4E4',
  backgroundColor: { fill:'transparent' },
   is3D: true,
}}
rootProps={{ 'data-testid': '7' }}
/>
</div>
  {dataTitle === "total"
?<p>SUMMARY  You will pay back in total: <span id="cc-term">{summary}</span></p>
:<p>SUMMARY you will pay in total <span id="cc-term">{summaryinterest}</span> worth of interest only</p>  }

{summaryDifference !== 0 &&

  <p>
  With this higher payment(s) you could save <span id="cc-term-sav">{summaryDifference}.</span>

  </p>



}


</div>


    <Divider horizontal>
      <Header as='h4'>
        <Icon name='bar chart' />
        Table
      </Header>
    </Divider>

    <Table definition>
    <Table.Body>
      <Table.Row>
        <Table.Cell width={2}>CATEGORIES</Table.Cell>
        <Table.Cell>DETAILS</Table.Cell>
        <Table.Cell>COST( Interest Only)</Table.Cell>
        <Table.Cell>Monthly Payment</Table.Cell>
        <Table.Cell>HIGHER PAYMENT</Table.Cell>
        <Table.Cell>DELETE</Table.Cell>
      </Table.Row>
      {display}
    </Table.Body>
    </Table>
  </React.Fragment>
}
  </div>
    </div>
)
}

export default OverAll
