import React,{useState} from 'react'
import Request from '../helpers/request';

import { Redirect } from "react-router-dom";

const Feedback= (props)=>{


const [feedback, setFeedback]=useState('')
const [name, setName]=useState('')

function handleSubmit(event){
  event.preventDefault()
  console.log({"name": name, "feedback": feedback});
  if (name === '' && feedback !== ''){
    postFeedback({"name": 'Anonymous', "feedback": feedback})
  }else if(name !== '' && feedback !== ''){
    postFeedback({"name": name, "feedback": feedback})
  }

}


function postFeedback(feed){
    const request = new Request();
    /*


      REMEMBER TO CHANGE ONCE YOU DEPLOY. EHH YOU WILL FORGET!



    */
    request.post("http://localhost:8080/feedbacks", feed).then((err, data)=>{
      if(err) console.log(err)
      else console.log(data)
    })
}
function handleNameChange(event){
  setName(event.target.value)
}
function handleFeedbackChange(event){
  setFeedback(event.target.value)
}
  return(
    <div  id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : 'cc-list-Mobile'} >
      <div id='max-width'>

        <h1>Welcome in Feedback page!</h1>
        <form onSubmit={handleSubmit} id={window.frames.innerWidth > 1049
        ? 'feedback-form-pc'
        : "feedback-form-Mobile"}>
        <h3>Feedback</h3>
          <textarea id='textarea' rows='10'cols='100' value={feedback}onChange={handleFeedbackChange}/>
          <h3>Name( Optional )</h3>
          <input id ='feedback-name' onChange={handleNameChange}/>
          <button type='submit' className='btn-feedback-submit'>Submit</button>
        </form>
      </div>

    </div>

  )

}
export default Feedback
