import React,{ useState,useEffect } from 'react'
import { Divider, Form,Header } from 'semantic-ui-react'
import { Button} from 'semantic-ui-react'


const BudgetTool =(props)=>{
const [budgetItems, setBudgetItems]= useState(props.budgettool)
// const [outgoings, setOutgoings] = useState(props.budgettool.outgoings)
// const [income, setIncome] = useState(props.budgettool.income)
const [totalIncome, setTotalIncome] = useState(0)
const [totalOutgoing, setTotalOutgoing] = useState(0.00)
const [description, setDescription]= useState('')
const [amount, setAmount]= useState('')


useEffect(()=>{
  calculateTotals()
  handledata()

},[budgetItems])


function handledata(){
props.handledataBT(budgetItems)
}

function handleDelete(item){
  let newArray = budgetItems
  setBudgetItems(budgetItems.splice(budgetItems.splice(budgetItems.indexOf(item),1)))
 
}


function calculateTotals(){
  let totalI = 0
  let totalO = 0
  for (var item of budgetItems){
    if(item.category === 'Outgoing'){
      totalO += parseFloat(item.amount)
    }
    else if (item.category === 'Income'){
      totalI += parseFloat(item.amount)
    }
  }

  setTotalIncome( parseFloat(totalI) )
  setTotalOutgoing( parseFloat(totalO) ) 

}



function handleSubmit(event){
  event.preventDefault()
  let newEntry = {
    name: (description).toUpperCase(),
    amount: (parseFloat(amount)).toFixed(2),
    category: event.target[2].value
  }
  setBudgetItems([...budgetItems, newEntry])
  calculateTotals()
  setDescription('')
  setAmount('')
} 

return (
  <div  id={window.frames.innerWidth > 1049
  ? 'cc-list'
  : 'cc-list-Mobile'}>
  <div id="max-width">
  <Form onSubmit={handleSubmit} id={window.frames.innerWidth > 1049
  ? 'form-position-big'
  : 'form-position'} color={'green'} >
  <Header as='h5' color={'green'}>PLEASE FILL IN DETAILS BELOW</Header>
       <Form.Group id={window.frames.innerWidth > 1049
       ? 'a'
       : 'form-align'} inverted>
       <Form.Field>
       <label>Description</label>
         <input className="input-amount" type="text"  name="type"   
          placeholder='Description' value={description}
          onChange={e=> setDescription(e.target.value)} />
         </Form.Field>

         <Form.Field>
         <label>Amount</label>
          <input type="number"step="0.01" 
          placeholder="Amount" value={amount}
          onChange={e=> setAmount(e.target.value)}/>
         </Form.Field>

         <Form.Field>
        <label>Category</label>
         <Form.Field  control='select'>
         <label>Category</label>
          <option value='Outgoing'>Outgoing</option>
          <option value='Income'>Income</option>
        </Form.Field>
          </Form.Field>


          <Form.Field>
          <label id="hide">.</label>
           <Button type='submit'>Add</Button>
          </Form.Field>

       <Divider hidden />

     </Form.Group>

  </Form>
  </div>
  <div id="max-width-budget-table">
  {budgetItems.length > 0  &&
          <div className="ui massive steps">
          <div className="step">
          {/* <p>INCOME</p> */}
          <i className="shield alternate icon"></i>
            <div className="content">
              <div className="title">{totalIncome}</div>
            </div>
          </div>
          <div className="ui step">
          <i className="chart pie icon"></i>
            {/* <p>OUTGOING</p> */}
            <div className="content">
              <div className="title">{totalOutgoing}</div>

            </div>
        </div>
        <div className="ui step">
        <i className="thumbtack icon"></i>
              <i className=""></i>
            {/* <p>LEFT</p> */}
            <div className="content">
              <div className="title">{totalIncome - totalOutgoing}</div>
            </div>
          </div>
        </div>
}
       
        {budgetItems.length > 0  &&
         <table className="ui celled table">
         <thead>
           <tr>
             <th>Description</th>
             <th>Category</th>
             <th>Notes</th>
             <th>Action</th>
           </tr>
         </thead>
         <tbody>
       {budgetItems.map(item =>{
          return (
            <tr key={budgetItems[item]} className={item.category === "Outgoing"
            ? "negative"
            : "positive"
            }>
              <td >{item.name}</td>
              <td >{item.category}</td>
              <td >{item.amount}</td>
              <td onClick={()=>handleDelete(item)} ><i className="icon close"></i></td>
            </tr>
          )
        })}
           </tbody>
    </table>





        }
       
   

    </div> 
  </div>
)


}


export default BudgetTool;




// <Segment placeholder>
//   <Grid columns={2} relaxed='very' stacable>
//
//     <Grid.Column>
//     <Header id='headercc'>Outgoings</Header>
//     <Segment id="bt-outcome">TOTAL:{totalOutgoing}</Segment>
//     <Container>
//
//
//     </Container>
//     {outgoings.map(inc =>{
//       return <Form onSubmit={handleDelete}><Segment id="outgoing">
//       {inc.name}  {inc.amount}
//       <button id='btool-button' type='submit' ><Icon name="x"/></button>
//       </Segment></Form>
//     })}
//     </Grid.Column>
//
//
//
//
//
//     <Grid.Column >
//       <Header id='headercc'>Income</Header>
//     <Segment id="bt-outcome">TOTAL: {totalIncome}</Segment>
//     <Container>
//
//     </Container>
//       {income.map(inc =>{
//         return <Form onSubmit={handleDelete}><Segment id='income'>
//         {inc.name}  {inc.amount}
//         <button id='btool-button'type='submit'><Icon name="x"/></button>
//
//         </Segment></Form>
//       })}
//     </Grid.Column>
//   </Grid>
//
//   <Divider vertical id="difference">{(totalIncome - totalOutgoing).toFixed(2) }</Divider>
//
// </Segment>
