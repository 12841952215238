import React from 'react'
import { Container, Header, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
const Home =(props)=>{





  return(
    <div  id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : "cc-list-Mobile"
      }>


    <div id={window.frames.innerWidth > 1049
    ? 'max-width-home-pc'
    : "max-width-home-Mobile"
      }>

    <div id={window.frames.innerWidth > 1049
    ? 'home-size-PC'
    : "home-size-Mobile"
      }>


    <Container>
    <Header as="h2">Welcome to Money Skilled</Header>
    <p>
    It was created to help you understand Your finances with few calculators to show you how they work.There is also a budgeting tool to help you see how much you have left from the month and how much you spend.
    </p>
    </Container>
    </div>

    <div  id={window.frames.innerWidth > 1049
    ? 'home-tip'
    : "home-tip-Mobile"
      }>
    <Icon inverted name='lightbulb'size="huge" />
    <h3>Get Started Now!</h3>
    <h4>This Section will explain how to use every Tool.</h4>


  <Link to='/getstarted'>  <span className='btn-feedback'>Get Started</span></Link>

    </div>



    </div>
      <div id={window.frames.innerWidth > 1049
      ? 'max-width-home-pc'
      : "max-width-home-Mobile"
        }>
      <div id={window.frames.innerWidth > 1049
      ? 'home-tip'
      : "home-tip-Mobile"
        }>
      <Icon inverted name='lightbulb'size="huge" />
      <h3>We'd love to hear from You!</h3>
      <h4>Please share your thoughts on this website with us.</h4>
      <Link to='/feedback'><span className='btn-feedback'>Leave Feedback</span></Link>
      </div>

        <div id={window.frames.innerWidth > 1049
        ? 'home-size-PC'
        : "home-size-Mobile"
          }>
        <Container>
        <Header as="h2">Sections</Header>
        <p>
        Every section was designed to help you understand financial products and calculate how much it will cost you to pay it off!
        </p>
        </Container>
        </div>


      </div>



    </div>

  )
}

export default Home;
