import React from 'react'
import { Container, Header,Icon} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const HintsAndTips =(props)=>{




  return(
    <div  id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : "cc-list-Mobile"
      }>
    <div id={window.frames.innerWidth > 1049
    ? 'max-width-home-pc'
    : "max-width-home-Mobile"
      }>
    <div id="hat-container">
    <Container>
    <Header as="h2">Efficient Strategies for Financial Management</Header>
    <Header as="h3">Introduction: Understanding and Managing Your Finances</Header>
    <p>
    In the pursuit of financial stability, it is crucial to address the fundamental question: "Where does my money go?" This inquiry is pivotal, as it lays the groundwork for effective financial management. If you often find yourself uncertain about your expenditures, consider employing a strategic approach to gain clarity on your financial activities over the past three months. By meticulously reviewing your bank statements and utilizing our Budget Tool, you can acquire an in-depth understanding of your spending patterns.
    </p>
    </Container>
    </div>
    <div  id={window.frames.innerWidth > 1049
    ? 'home-tip'
    : "home-tip-Mobile"
      }>
    <Icon name='lightbulb outline' id='hat-icon'size='huge'/>

    </div>



    </div>
  <div id="hat-container">
    <Container>
    <Header as="h2">Maintaining Fiscal Discipline</Header>
    <p><span id='hat-span'>1.</span>Categorize and Separate Expenditures:
    <p id="hat-li">To maintain control over your finances, segregate your spending into distinct categories. Create two separate accounts—one dedicated to covering bills and another for discretionary spending. Allocate the necessary funds to your bills account promptly upon receiving your income. This proactive approach ensures that your financial obligations are met, leaving you with a clear overview of disposable income until the next payday.</p>
    </p>

    <p><span id='hat-span'>2.</span>Daily Account Monitoring and Savings:
    <p id="hat-li">Develop a habit of monitoring your bank account on a nightly basis. Any surplus funds beyond rounded figures can be transferred to your savings account, fostering a simplified and more manageable balance. For example, if your account balance is $1376.27, consider transferring $6.27 to your savings, resulting in a more easily remembered balance of $1370. This practice provides a real-time snapshot of your available funds.</p>
    </p>

    <p><span id='hat-span'>3.</span>Transaction Tracking Through a Spreadsheet:
    <p id="hat-li">Facilitate comprehensive financial monitoring by maintaining a spreadsheet of all transactions. Regularly inputting your expenditures throughout the month enables you to track your total spending accurately. This method ensures a meticulous overview of your financial activities, aiding in informed decision-making.</p>
    </p>
    
    <Header as="h2">Strategies for Effective Savings</Header>
    
    <p><span id='hat-span'>1.</span>Goal-Oriented Savings:
    <p id="hat-li">Define specific financial goals to guide your savings efforts. Knowing the precise amount required and the intended purpose enhances the effectiveness of your saving strategy. Prioritize your goals based on urgency and importance.</p>
    </p>

    <p><span id='hat-span'>2.</span>Opening a Dedicated Savings Account:
    <p id="hat-li">Establishing a dedicated savings account is a rapid and secure method for accumulating funds. While most savings accounts offer instant access, consider exploring options that impose restrictions, such as requiring in-person visits or limited accessibility. This can act as a deterrent to impulsive spending. Verify the account offerings on your bank's website to identify a suitable option.</p>
    </p>

    <Header as="h2">Conclusion:</Header>
    <p id="hat-li">By implementing these systematic financial practices, you can gain control over your expenditures, track your progress, and work toward achieving your financial objectives. Taking a proactive approach to managing your money not only fosters fiscal discipline but also sets the foundation for long-term financial success.</p>

</Container>

  <div id='hat-container'>
    <Container>
    {/* <Header as="h2">Saving</Header>
    <p><span id='hat-span'>1.</span>Goal-Oriented Savings:</p>
    <p><span id='hat-span'>2.</span>Define specific financial goals to guide your savings efforts. Knowing the precise amount required and the intended purpose enhances the effectiveness of your saving strategy. Prioritize your goals based on urgency and importance.</p>

    <p><span id='hat-span'>2.</span>Now that we have where to put our money way. We need to work on </p> */}
    </Container>
    </div>
  </div>
      <div id={window.frames.innerWidth > 1049
      ? 'max-width-home-pc'
      : "max-width-home-Mobile"
        }>
      <div  id={window.frames.innerWidth > 1049
      ? 'home-tip'
      : "home-tip-Mobile"
        }>
      <Icon inverted name='lightbulb'size="huge" />
      <h3>We'd love to hear from You!</h3>
      <h4>Please share your thought on this website with us.</h4>
      <Link to="/feedback" className='btn-feedback'>Leave Feedback</Link>
      </div>

        <div id={window.frames.innerWidth > 1049
        ? 'home-size-PC'
        : "home-size-Mobile"
          }>
        <Container>
        <Header as="h2">We hope That was useful.</Header>
        <p>
        If you found this webiste helpful please share it with your friends.
        </p>
        </Container>
        </div>


      </div>

    </div>

  )
}

export default HintsAndTips;
