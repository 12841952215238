import React from 'react'

import { Link} from 'react-router-dom';
const Footer= (props)=>{



  return(
    <div  id="footer" >

      <div  id={window.frames.innerWidth > 1049
        ? "sitemap"
        :"sitemap-mobile"
      }>
        <h3 id="sitemap-header">Site Map</h3>
        <div className='overlay-active'></div>
        <ul className="sitemap-lists">

        <Link to='/home'>  <p className='link-sitemap' >Home</p></Link>
        <Link to='/getstarted'>  <p className='link-sitemap' >Get Started</p></Link>
        <Link to='/feedback'>  <p className='link-sitemap' >Feedback</p></Link>
        <Link to='/hintsandtips'>  <p className='link-sitemap' >Hints and Tips</p></Link>
        </ul>

        <ul className="sitemap-lists">
        <Link to='/budgettool'>  <p className='link-sitemap' >Budget Tool</p></Link>
        <Link to='/creditcard'>  <p className='link-sitemap' >Credit Card</p></Link>
        <Link to='/personalloan'>  <p className='link-sitemap'>Loan</p></Link>

        <Link to='/overall'>  <p className='link-sitemap' >Overall</p></Link>
        </ul>

      </div>

      <div id="copyrights">
        <p>&copy; 2020</p>
        <p>The information provided on moneyskilled.com is for general  informational purposes only.</p>
      </div>



    </div>

  )

}
export default Footer
