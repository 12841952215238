import React,{useState,useEffect} from 'react';
import '../App.css';
import { Form,Segment,Container,Header,Grid } from 'semantic-ui-react'


const Loan = (props)=>{
  const [loanAmount, setLoanAmount] = useState(props.loandata.amount)
  const [loanInterest, setLoanInterest] = useState(props.loandata.interest)
  const [loanTerm, setLoanTerm]=useState(props.loandata.term)
  const [monthlyPayment, setMonthlyPayment]=useState(0.00)
  const [totalPayable, setTotalPayable]=useState(null)
  const [overallInterest,setOverallInterest]= useState(null)
  const [readyToDisplay, setReadyToDisplay] = useState(false)






  useEffect(()=>{
    calculateLoan()
    handledataLoan()
  }, [loanAmount, loanInterest, loanTerm, overallInterest])


  function handledataLoan(){
    props.handledataLoan({
      amount: loanAmount,
      interest: loanInterest,
      term: loanTerm
    })
  }

  function calculateLoan(){

      const overallInterestt = loanAmount * ((loanInterest/100)/1.763) * (loanTerm)

      setTotalPayable((overallInterestt + loanAmount).toFixed(2))
      const x = (overallInterestt + loanAmount) / (loanTerm*12)
      if(loanAmount === null || loanTerm === null){
        setMonthlyPayment((0.00).toFixed(2))
        setReadyToDisplay(false)
      }else{
      setMonthlyPayment((x).toFixed(2))
      setReadyToDisplay(true)
      }
      setOverallInterest((overallInterestt).toFixed(2))
  }


  function handleLoanAmountChange(event){
    setLoanAmount(parseInt(event.target.value))
  }
  function handleLoanInterestChange(event){
    setLoanInterest(parseFloat(event.target.value))

  }
  function handleLoanTermChange(event){
    setLoanTerm(parseInt(event.target.value))
  }

  return(

    <div id={window.frames.innerWidth > 1049
    ? 'cc-list'
    : 'cc-list-Mobile'}>

  <div id='max-width'>






  <Form id={window.frames.innerWidth > 1049
  ? 'form-position-big'
  : 'form-position'} color={'green'} >
  <Header as='h5' color={'green'}>PLEASE FILL IN DETAILS OF YOUR LOAN</Header>
       <Form.Group id={window.frames.innerWidth > 1049
       ? 'a'
       : 'form-align'} inverted>

       <Form.Field>
       <label>Amount Borrowed</label>
         <input className="input-amount" type="number"  name="amount"  value={loanAmount} onChange={handleLoanAmountChange} placeholder='Amount' />
         <p class="examples">example: 5000</p>
       </Form.Field>
       <Form.Field>
       <label>Interest (APR)</label>
         <input className="input-amount" type="number"  name="amount"  value={loanInterest} onChange={handleLoanInterestChange} placeholder='Interest' />
         <p class="examples">example: 8</p>
       </Form.Field>
       <Form.Field>
       <label>Term in Year(s)</label>
         <input className="input-amount" type="number"  name="amount"  value={loanTerm} onChange={handleLoanTermChange} placeholder='Term' />
         <p class="examples">example: 5</p>
       </Form.Field>

     </Form.Group>
  </Form>













{readyToDisplay &&
    <Segment placeholder id="segment">
        <Grid columns={1} relaxed='very' stackable>


          <Grid.Column verticalAlign='middle'>


          <Header id="headercc">Outcome</Header>


            <Container>
             <Segment size="small"><span id="loan-term">{overallInterest}</span> Overall Interest</Segment><Segment size="small"><span id='loan-term'>{totalPayable}</span> Total Due</Segment>
             <Segment size="small"><span  id="loan-term" >{monthlyPayment}</span> Monthly Payment</Segment>
            </Container>
          </Grid.Column>
        </Grid>


      </Segment>


}
      </div>
  </div>

  );
}

export default Loan;
